<script setup>
import { RouterLink, RouterView } from 'vue-router'

</script>

<template>
  <header class="header">
    <nav class="flex items-center justify-between flex-wrap p-6 background-black" :class="{'is-home': $route.name === 'home', 'open': showMobileNav}">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <a href="/" @click="hideMobileNav"><img :src="logo" class="h-10 w-10 mr-2" /></a>
        <span class="text-2xl tracking-tight font-philosopher tracking-wider pb-2 pl-1">pixdeck</span>
      </div>
      <div class="block lg:hidden">
        <button class="flex items-center px-3 py-2 border rounded text-yellow-400 border-yellow-400 hover:text-white hover:border-white" @click="toggleMobileNav">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div :class="`w-full block flex-grow lg:flex lg:items-center lg:w-auto  ${mobileNavClass}`">
        <div class="text-sm lg:flex-grow">
          <a  @click="hideMobileNav" class="block mt-4 lg:inline-block lg:mt-0 text-yellow-400 hover:text-white mr-4" href="/">Create a Skybox</a>
          <a  @click="hideMobileNav" class="block mt-4 lg:inline-block lg:mt-0 text-yellow-400 hover:text-white mr-4" href="/create">Create Images</a>
          <a  @click="hideMobileNav" class="block mt-4 lg:inline-block lg:mt-0 text-yellow-400 hover:text-white mr-4" href="/about">About</a>
          <a  @click="hideMobileNav" class="block mt-4 lg:inline-block lg:mt-0 text-yellow-400 hover:text-white mr-4" href="/account" v-if="auth.authStatus === 'authenticated'">Account</a>
        </div>
        <div class="mr-3 mt-3 lg:mt-0">
          <authenticator variation="modal" :class="{hidden: !showSignIn}"  >
            <template v-slot:header>
              <div class="bg-white text-right px-4 py-2 font-bold text-xl">
                <button class="cancel" @click="cancelSignin">x</button>
              </div>
            </template>
          </authenticator>
          <a href="/account" v-if="auth.authStatus === 'authenticated'" class="hidden lg:block">
            <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <span class="font-medium text-gray-600 dark:text-gray-300">{{user.attributes.email[0]}}</span>
            </div>
          </a>
          <button  v-if="auth.authStatus != 'authenticated'" class="block mt-4 lg:inline-block lg:mt-0 text-yellow-400 hover:text-white mr-4" href="/login" @click="signIn">Sign in</button>
        </div>
      </div>
    </nav>
  </header>
  <RouterView />
  
</template>

<script>
  import { Auth } from 'aws-amplify';
  import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue';
  import '@aws-amplify/ui-vue/styles.css';

  import { Amplify } from 'aws-amplify';
  import awsconfig from './aws-exports';
  import { Hub, Logger } from 'aws-amplify';
  import logo from '/src/assets/logo.png';

  Amplify.configure(awsconfig);
  const auth = useAuthenticator();
  const logger = new Logger('My-Logger', 'DEBUG');

  export default {

  mounted() {
    Hub.listen('auth', this.handleAuthEvents);
  },
  data() {
    return {
      showMobileNav: false,
      showSignIn: false,
    }
  },
  computed: {
    mobileNavClass() {
      return this.showMobileNav ? '' : 'hidden lg:block'
    },
    user: function() {
      return Auth.user;
    }
  },
  methods: {
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav;
    },
    hideMobileNav() {
      this.showMobileNav = false;
    },
    signIn() {
      this.showSignIn = true;
    },
    cancelSignin() {
      this.showSignIn = false;
    },
    handleAuthEvents(data){
      switch (data.payload.event) {
        case 'configured':
          logger.info('the Auth module is configured');
          break;
        case 'signIn':
          logger.info('user signed in');
          this.$router.go()
          break;
        case 'signIn_failure':
          logger.error('user sign in failed');
          break;
        case 'signUp':
          logger.info('user signed up');
          break;
        case 'signUp_failure':
          logger.error('user sign up failed');
          break;
        case 'confirmSignUp':
          logger.info('user confirmation successful');
          // this.$router.go()
          break;
        case 'completeNewPassword_failure':
          logger.error('user did not complete new password flow');
          break;
        case 'autoSignIn':
          logger.info('auto sign in successful');
          break;
        case 'autoSignIn_failure':
          logger.error('auto sign in failed');
          break;
        case 'forgotPassword':
          logger.info('password recovery initiated');
          break;
        case 'forgotPassword_failure':
          logger.error('password recovery failed');
          break;
        case 'forgotPasswordSubmit':
          logger.info('password confirmation successful');
          this.$router.go()
          break;
        case 'forgotPasswordSubmit_failure':
          logger.error('password confirmation failed');
          break;
        case 'verify':
          logger.info('TOTP token verification successful');
          break;
        case 'tokenRefresh':
          logger.info('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          logger.error('token refresh failed');
          break;
        case 'cognitoHostedUI':
          logger.info('Cognito Hosted UI sign in successful');
          break;
        case 'cognitoHostedUI_failure':
          logger.error('Cognito Hosted UI sign in failed');
          break;
        case 'customOAuthState':
          logger.info('custom state returned from CognitoHosted UI');
          break;
        case 'customState_failure':
          logger.error('custom state failure');
          break;
        case 'parsingCallbackUrl':
          logger.info('Cognito Hosted UI OAuth url parsing initiated');
          break;
        case 'userDeleted':
          logger.info('user deletion successful');
          break;
        case 'updateUserAttributes':
          logger.info('user attributes update successful');
          break;
        case 'updateUserAttributes_failure':
          logger.info('user attributes update failed');
          break;
        case 'signOut':
          logger.info('user signed out');
          if (this.$route.path != "/") {
            this.$router.replace("/")
          } else {
            this.$router.go()
          }
          break;
      }
    }
  }
}

</script>


<style lang="scss">
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
}

body {
  background-color: black;
  overscroll-behavior-y: none;
}

button[type='submit'] {
  background-color: var(--amplify-components-button-primary-background-color);
}

#app {
  position: absolute;
  top: 0;
}

.background-black {
  background-color: black;

  &.is-home {
    background-color: transparent;
  
    &.open {
      background-color: rgba(0,0,0,0.5);
    }
  }
}
</style>
