import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import './assets/main.css'

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import Vue3ConfirmDialog from 'vue3-confirm-dialog';
import 'vue3-confirm-dialog/style';

Amplify.configure(awsExports);

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(Vue3ConfirmDialog)

app.mount('#app')
app.component('vue3-confirm-dialog', Vue3ConfirmDialog.default)