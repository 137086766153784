<template>
  <main>
    <div id="container"></div>
      <form id="info" class="info bg-gray-700 bg-opacity-70 shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <textarea type="text" :placeholder="placeholder" id="prompt" class="overflow-auto resize-y form-input mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300" v-model="prompt" />
        <div class="flex items-center mt-2">
          <button id="generate" :disabled="loading || !this.prompt" v-if="jwt" class="bg-yellow-500 mr-3 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="generate">Generate <span class="cost">{{ cost }}</span></button>
          <a v-if="jwt && imageURL" :href="imageURL" target="_blank" class="ml-4 "><ArrowDownTrayIcon class="h-8 w-8 text-white background-gray-300 cursor-pointer"/></a>
          <button id="prev" v-if="!jwt" class="bg-yellow-500 mr-3 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="prevExample">Previous</button>
          <button id="next" v-if="!jwt" class="bg-yellow-500 mr-3 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="nextExample">Next</button>
          <div v-if="jwt && ('credits' in userAccount)" class="text-yellow-400 text-sm text-right flex-grow">
            <BanknotesIcon class="mr-1 h-6 w-6 text-yellow-400 background-gray-300 inline"  />
            {{(Math.round(userAccount.credits * 100) / 100).toFixed(2)}}
          </div>
        </div>
        <p v-if="!jwt" class="text-yellow-400 mt-3 text-xs">Sign up for a free account to create new images</p>
      </form>
      <CircleProgressBar v-if="loading" class="progress" :value="generationProgress" :max="100" >{{ progressMessage }}</CircleProgressBar>
  </main>
</template>

<script>
import * as THREE from 'three';
import {CircleProgressBar} from 'vue3-m-circle-progress-bar';
import exampleImage1 from '/src/assets/skybox1.jpg';
import exampleImage2 from '/src/assets/skybox2.jpg';
import exampleImage3 from '/src/assets/skybox3.jpg';
import exampleImage4 from '/src/assets/skybox4.jpg';
import exampleImage5 from '/src/assets/skybox5.jpg';
import exampleImage6 from '/src/assets/skybox6.jpg';
import exampleImage7 from '/src/assets/skybox7.jpg';

import { Auth } from 'aws-amplify';
import { BanknotesIcon, ArrowDownTrayIcon } from '@heroicons/vue/24/solid'

const START_EXAMPLE_INDEX = 6
let camera, scene, renderer;

let isUserInteracting = false,
  onPointerDownMouseX = 0, onPointerDownMouseY = 0,
  lon = 0, onPointerDownLon = 0,
  lat = 0, onPointerDownLat = 0,
  phi = 0, theta = 0;

const API_URL = import.meta.env.VITE_API_URL;
const IMAGE_URL = import.meta.env.VITE_IMAGE_URL;

const examples = [
{
  "filename": exampleImage1,
  "progress": 100,
  "prompt": "Mountain chalet by Frank Lloyd Wright",
  "outputType": "skybox",
  "id": "1",
  "duration": 60,
  "name": "test",
  "state": "complete"
},
{
  "filename": exampleImage2,
  "progress": 100,
  "prompt": "hyper realistic photo of a mid century modern style house overlooking the ocean, daylight, indirect lighting, AD magazine, Frank Lloyd, Eames, Mies van der Rohe, ocean view",
  "outputType": "skybox",
  "id": "1fac2abc-e5f5-4228-bcea-8d4285cb9781",
  "duration": 60,
  "name": "test",
  "state": "complete"
},
{
  "filename": exampleImage3,
  "userId": "a59a07f5-4c81-4706-8cb4-2fd789248abc",
  "progress": 100,
  "prompt": "valley, fairytale treehouse village covered, matte painting, dynamic lighting, cinematic, realism, realistic, sunset, high contrast, denoised, centered, michael whelan",
  "outputType": "skybox",
  "id": "e3af1c3a-9114-4f29-a8bb-1159ed6122c3",
  "duration": 59,
  "name": "test",
  "state": "complete"
},
{
  "filename": exampleImage4,
  "userId": "a59a07f5-4c81-4706-8cb4-2fd789248abc",
  "progress": 100,
  "prompt": "imaginary dreamy style shaped 3D villa retro futuristic 70's interior design, on a finnish lakeshore, rendered in Octane by Joe Mortell, architecture by Frank Lloyd Wright, 8k blender photorealism, neutral beige color scheme, organic lines, earthship architecture",
  "outputType": "skybox",
  "id": "5a6ced9d-841b-4bbf-b593-8c0e1dc89b1b",
  "duration": 59,
  "name": "test",
  "state": "complete"
},
{
  "filename": exampleImage5,
  "userId": "a59a07f5-4c81-4706-8cb4-2fd789248abc",
  "progress": 100,
  "prompt": " a big Persian Villa surrounded by water and nature, village,  magical, flowers, massive cliffs",
  "outputType": "skybox",
  "id": "84b8bf5f-b3f4-424b-90c1-4783c42fd5c2",
  "duration": 60,
  "name": "test",
  "state": "complete"
},
{
  "filename": exampleImage6,
  "config": {},
  "userId": "a59a07f5-4c81-4706-8cb4-2fd789248abc",
  "progress": 100,
  "createdAt": "2023-05-17T21:19:38.569Z",
  "prompt": "view from outside a castle, celtic, ultra realistic, ultra detailed, 8k, symetrical, ultra realistic, ultra detailed, 8k, cinematic theme ",
  "outputType": "skybox",
  "id": "76d86489-2984-4262-acf0-e54b79b90d8e",
  "duration": 61,
  "name": "test",
  "state": "complete"
},
{
  "filename": exampleImage7,
  "userId": "a59a07f5-4c81-4706-8cb4-2fd789248abc",
  "progress": 100,
  "prompt": "hyperrealistic organic Fallingwater, underwater biological pool, style hobbit, gaudi, zaha hadi, Javier Senosiain, rustic plaster earth terra cotta, in tropical forest garden, cinematic, hdr, concept art, soft render, highly detailed, cgsociety, octane render, architectural HD, HQ, 4k, 8k",
  "outputType": "skybox",
  "id": "84b8bf5f-b3f4-424b-90c1-4783c42fd5c2",
  "duration": 60,
  "name": "test",
  "state": "complete"
},
]

function init() {

  const container = document.getElementById( 'container' );

  camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1100 );

  scene = new THREE.Scene();

  const geometry = new THREE.SphereGeometry( 500, 60, 40 );
  // invert the geometry on the x-axis so that all of the faces point inward
  geometry.scale( - 1, 1, 1 );

  // const texture = new THREE.TextureLoader().load( 'sdui_outputs/txt2img-images/2023-03-11/00173-2741702188.png' );
  const texture = new THREE.TextureLoader().load( examples[START_EXAMPLE_INDEX].filename );
  const material = new THREE.MeshBasicMaterial( { map: texture } );

  const mesh = new THREE.Mesh( geometry, material );

  scene.add( mesh );

  renderer = new THREE.WebGLRenderer();
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );
  container.appendChild( renderer.domElement );

  container.style.touchAction = 'none';
  container.addEventListener( 'pointerdown', onPointerDown );

  document.addEventListener( 'wheel', onDocumentMouseWheel );

  //

  document.addEventListener( 'dragover', function ( event ) {

    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';

  } );

  document.addEventListener( 'dragenter', function () {

    document.body.style.opacity = 0.5;

  } );

  document.addEventListener( 'dragleave', function () {

    document.body.style.opacity = 1;

  } );

  document.addEventListener( 'drop', function ( event ) {

    event.preventDefault();

    const reader = new FileReader();
    reader.addEventListener( 'load', function ( event ) {
      material.map.image.src = event.target.result;
      material.map.needsUpdate = true;

    } );
    reader.readAsDataURL( event.dataTransfer.files[ 0 ] );

    document.body.style.opacity = 1;

  } );

  window.addEventListener( 'resize', onWindowResize );

  return material;

}

function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );

}

function onPointerDown( event ) {

  if ( event.isPrimary === false ) return;

  isUserInteracting = true;

  onPointerDownMouseX = event.clientX;
  onPointerDownMouseY = event.clientY;

  onPointerDownLon = lon;
  onPointerDownLat = lat;

  document.addEventListener( 'pointermove', onPointerMove );
  document.addEventListener( 'pointerup', onPointerUp );

}

function onPointerMove( event ) {
  if ( event.isPrimary === false ) return;

  lon = ( onPointerDownMouseX - event.clientX ) * 0.1 + onPointerDownLon;
  lat = ( event.clientY - onPointerDownMouseY ) * 0.1 + onPointerDownLat;
}

function onPointerUp(event) {

  if ( event.isPrimary === false ) return;

  isUserInteracting = false;

  document.removeEventListener( 'pointermove', onPointerMove );
  document.removeEventListener( 'pointerup', onPointerUp );
}

function onDocumentMouseWheel( event ) {
  const fov = camera.fov + event.deltaY * 0.05;
  camera.fov = THREE.MathUtils.clamp( fov, 10, 75 );
  camera.updateProjectionMatrix();
}

let animationId = null;

function animate() {
  animationId = requestAnimationFrame( animate );
  update();
}

function update() {

  if ( isUserInteracting === false ) {
    lon += 0.05;
  }

  lat = Math.max( - 85, Math.min( 85, lat ) );
  phi = THREE.MathUtils.degToRad( 90 - lat );
  theta = THREE.MathUtils.degToRad( lon );

  const x = 500 * Math.sin( phi ) * Math.cos( theta );
  const y = 500 * Math.cos( phi );
  const z = 500 * Math.sin( phi ) * Math.sin( theta );

  camera.lookAt( x, y, z );

  renderer.render( scene, camera );

}

export default {
  mounted() {
    this.material = init();
    animate();

    Auth.currentAuthenticatedUser().then(user=>{

      const token = user.signInUserSession.idToken.jwtToken;

      this.jwt = token;

      if (this.$route.query.id) {
        this.imageID = this.$route.query.id;
        this.fetchData();
      }

      this.fetchUser();
    }).catch(err=>{
      this.prompt = examples[START_EXAMPLE_INDEX].prompt;
    });

  },
  unmounted() {
    cancelAnimationFrame(animationId);
  },
  components: {
    'CircleProgressBar': CircleProgressBar,
    'BanknotesIcon': BanknotesIcon,
    'ArrowDownTrayIcon': ArrowDownTrayIcon,
  },
  data() {
    return {
      prompt: null,
      placeholder: examples[START_EXAMPLE_INDEX].prompt,
      loading: false,
      generationProgress: null,
      imageID: null,
      pollingInterval: null,
      material: null,
      jwt: null,
      exampleIndex:START_EXAMPLE_INDEX,
      userAccount: {},
      cost: 2,
      imageURL: null,
    }
  },
  computed: {
    progressMessage() {
      if (this.generationProgress === undefined || this.generationProgress === null) {
        return `Queued...`;
      } else {
        return this.generationProgress + '/100';
      }
    },
  },
  methods: {
    nextExample(e) {
      e.preventDefault();
      this.exampleIndex = (this.exampleIndex + 1) % examples.length;
      this.loadData(examples[this.exampleIndex])
    },
    prevExample(e) {
      e.preventDefault();
      this.exampleIndex = (this.exampleIndex - 1 + examples.length) % examples.length;
      this.loadData(examples[this.exampleIndex])
    },
    async getIdJwtToken () {
      // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    },
    async generate(event) {
      // console.log(this.prompt);
      event.preventDefault();
      this.loading = true;
      this.generationProgress = null;
      this.jwt = await this.getIdJwtToken();

      const url = `${API_URL}/jobs`;
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: this.jwt,
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          "outputType": "skybox",
          "name": "test", 
          "state": "new",
          "prompt": this.prompt,
          "config": {
            // seed: 982578412
          }
        }),
      };
      fetch(url, options)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          this.userAccount.credits -= this.cost;
          this.imageID = data.id;
          this.startPollingProgress();
        });
    },
    startPollingProgress() {
      this.pollingInterval = setInterval(this.fetchProgress, 3000) //save reference to the interval
      setTimeout(() => { clearInterval(this.pollingInterval) }, 36000000) //stop polling after an hour
    },
    populateSettings(prompt, config) {
      this.prompt = prompt;
    },
    fetchData() {
      fetch(`${API_URL}/jobs?id=${this.imageID}`,{headers: {
                'Content-Type': 'application/json',
                'Authorization' :  this.jwt 
            }})
        .then((response) => {
            return response.json();
        }).then((data) => {
            this.generationProgress = data.progress;
            if (data.state === 'complete') {
                this.loading = false;
                clearInterval(this.pollingInterval) //won't be polled anymore 
                this.material.map.image.src = `${IMAGE_URL}/${data.filename}`;
                this.imageURL = `${IMAGE_URL}/${data.filename}`;
                this.material.map.needsUpdate = true;
                this.$router.push({ path: '/', query: { id: this.imageID }})
            } else {
              this.loading = true;
              this.startPollingProgress();
            }
            this.status = data.state;
            this.populateSettings(data.prompt, data.config)
        }).catch((error) => {
            // console.log(error);
        });
    },
    fetchProgress() {
      fetch(`${API_URL}/jobs?id=${this.imageID}`,{headers: {
                'Content-Type': 'application/json',
                'Authorization' :  this.jwt 
            }})
        .then((response) => {
            return response.json();
        }).then((data) => {
            this.generationProgress = data.progress;
            if (data.state === 'complete') {
                this.loading = false;
                clearInterval(this.pollingInterval) //won't be polled anymore 
                this.material.map.image.src = `${IMAGE_URL}/${data.filename}`;
                this.material.map.needsUpdate = true;
                this.$router.push({ path: '/', query: { id: this.imageID }})
            } 
            this.status = data.state;
        }).catch((error) => {
            // console.log(error);
        });
    },
    fetchUser() {
      fetch(`${API_URL}/users`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' :  this.jwt 
            }
      })
        .then((response) => {
            return response.json();
        }).then((data) => {
            this.userAccount = data[0];
        }).catch((error) => {
            //console.log(error);
        });
    },
    loadData(data) {
      this.material.map.image.src = `${data.filename}`;
      this.imageURL = `${data.filename}`;
      this.material.map.needsUpdate = true;
      this.populateSettings(data.prompt, data.config)
    }
  },
}
</script>

<style scoped lang="scss">
.info {
  position: absolute;
  bottom: 50px;
  margin: auto;
  margin-left: 15%;
  width: 70%;

  .prompt {
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
    padding: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0;
    left: 5%;
    min-width: 300px;
  }
}

.progress {
  position: absolute;
  color: aqua;
  width: 104px;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
}

.cost {
  background-color: rgba(0,0,0,0.1);
  padding: 3px 5px;
  border-radius: 3px;
}

button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

@media (hover: hover) {
  button {
    @apply hover:bg-yellow-700;
  }
}


</style>
